(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeColumnsGallery = void 0;
var lazy_loading_tools_1 = require("./lazy-loading-tools");
function initializeColumnsGallery(wrapper, config) {
    (0, lazy_loading_tools_1.initializeWhenOnViewport)(config.lazyLoading, function () {
        initializeMasonry(wrapper, config);
    }, wrapper);
}
exports.initializeColumnsGallery = initializeColumnsGallery;
function initializeMasonry(wrapper, config) {
    var $wrapper = $(wrapper);
    // @ts-ignore
    $wrapper.masonry({
        itemSelector: config.itemSelector,
        columnWidth: config.columnMeasurer,
        stagger: 0,
        transitionDuration: 0,
        resize: false,
        gutter: config.gutter,
    });
    $wrapper.on("layoutComplete", function () {
        var sizerW = config.columnMeasurer.clientWidth;
        wrapper.style.setProperty("--calc-w", sizerW + "px");
    });
    // This is a fix to window resizing that sometimes does not happen properly
    // @ts-ignore
    var throttledResizeHandler = $.debounce(150, function () {
        // @ts-ignore
        $wrapper.masonry("layout");
        setTimeout(function () {
            // @ts-ignore
            $wrapper.masonry("layout");
        }, 200);
        setTimeout(function () {
            // @ts-ignore
            $wrapper.masonry("layout");
        }, 1200);
    });
    var resizeBreakpointHandler = function () {
        if (config.mobileBreakpoint && window.innerWidth < config.mobileBreakpoint) {
            wrapper.classList.add('cg-columns-gallery--mobile');
        }
        else {
            wrapper.classList.remove('cg-columns-gallery--mobile');
        }
    };
    $(window).on("resize", throttledResizeHandler);
    $(window).on("resize", resizeBreakpointHandler);
    resizeBreakpointHandler();
    // @ts-ignore
    $wrapper.imagesLoaded()
        .progress(function () {
        // @ts-ignore
        $wrapper.masonry("layout");
    });
    if (config.effect) {
        var allImagesLoaded_1 = false;
        var isOnViewport_1 = false;
        // @ts-ignore
        $wrapper.imagesLoaded(function () {
            allImagesLoaded_1 = true;
            if (isOnViewport_1) {
                runEntryEffect($wrapper);
            }
        });
        var observer_1 = new IntersectionObserver(function (entries) {
            entries.forEach(function (e) {
                if (e.target === wrapper && e.isIntersecting) {
                    observer_1.disconnect();
                    isOnViewport_1 = true;
                    setTimeout(function () {
                        if (allImagesLoaded_1) {
                            runEntryEffect($wrapper);
                        }
                        setTimeout(function () {
                            if (!allImagesLoaded_1) {
                                runEntryEffect($wrapper);
                            }
                        }, 2500);
                    }, 300);
                }
            });
        }, {
            rootMargin: '-50px'
        });
        observer_1.observe(wrapper);
    }
}
function runEntryEffect($wrapper) {
    if ($wrapper.is('.cg-gallery--before-load')) {
        $wrapper.removeClass("cg-gallery--before-load");
        $wrapper.addClass("cg-gallery--entering");
        setTimeout(function () {
            $wrapper.removeClass('cg-gallery--entering');
        }, 2000);
    }
}



},{"./lazy-loading-tools":3}],2:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var photoswipe_tools_1 = require("./photoswipe-tools");
var columns_gallery_1 = require("./columns-gallery");
var strip_gallery_1 = require("./strip-gallery");
var swiper_gallery_1 = require("./swiper-gallery");
var mosaic_gallery_1 = require("./mosaic-gallery");
var single_gallery_1 = require("./single-gallery");
var rows_gallery_1 = require("./rows-gallery");
window['AnimatoGallery'] = {
    initializePhotoswipeInGallery: photoswipe_tools_1.initPhotoswipe,
    initializeColumnsGallery: columns_gallery_1.initializeColumnsGallery,
    initializeRowsGallery: rows_gallery_1.initializeRowsGallery,
    initializeStripGallery: strip_gallery_1.initializeStripGallery,
    initializeSwiperGallery: swiper_gallery_1.initializeSwiperGallery,
    initializeMosaicGallery: mosaic_gallery_1.initializeMosaicGallery,
    initializeSingleGallery: single_gallery_1.initializeSingleGallery,
};



},{"./columns-gallery":1,"./mosaic-gallery":4,"./photoswipe-tools":5,"./rows-gallery":6,"./single-gallery":7,"./strip-gallery":8,"./swiper-gallery":9}],3:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeWhenOnViewport = void 0;
function initializeWhenOnViewport(isLazyLoadingActive, initFunction, watchedElement, removeLazyLoadingAttr) {
    if (removeLazyLoadingAttr === void 0) { removeLazyLoadingAttr = true; }
    if (!isLazyLoadingActive) {
        initFunction();
        return;
    }
    var observer = new IntersectionObserver(function (observableEntries) {
        observableEntries.forEach(function (entry) {
            if (entry.target === watchedElement && entry.isIntersecting) {
                if (removeLazyLoadingAttr) {
                    watchedElement.querySelectorAll('[loading="lazy"]').forEach(function (el) { return el.removeAttribute('loading'); });
                }
                initFunction();
                observer.disconnect();
            }
        });
    }, {
        rootMargin: (window.innerHeight > 600 ? '600px' : '100%'),
    });
    observer.observe(watchedElement);
}
exports.initializeWhenOnViewport = initializeWhenOnViewport;



},{}],4:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeMosaicGallery = void 0;
function initializeMosaicGallery(wrapper, params) {
    var $wrapper = $(wrapper);
    if (params.mobile) {
        window.addEventListener('resize', function () {
            evalMobileVersion(wrapper, params.mobile);
        });
        evalMobileVersion(wrapper, params.mobile);
    }
    if (params.effect) {
        var imagesLoaded_1 = false;
        var isIntersecting_1 = false;
        // @ts-ignore
        $wrapper.imagesLoaded(function () {
            imagesLoaded_1 = true;
            if (isIntersecting_1) {
                runEntryEffect($wrapper);
            }
        });
        var observer_1 = new IntersectionObserver(function (entries) {
            entries.forEach(function (e) {
                if (e.target === wrapper && e.isIntersecting) {
                    observer_1.disconnect();
                    isIntersecting_1 = true;
                    setTimeout(function () {
                        if (imagesLoaded_1) {
                            runEntryEffect($wrapper);
                        }
                    }, 300);
                    setTimeout(function () {
                        runEntryEffect($wrapper);
                    }, 3000);
                }
            });
        }, {
            rootMargin: '-50px',
        });
        observer_1.observe(wrapper);
    }
}
exports.initializeMosaicGallery = initializeMosaicGallery;
function runEntryEffect($wrapper) {
    if ($wrapper.is('.cg-gallery--before-load')) {
        $wrapper.removeClass("cg-gallery--before-load");
        $wrapper.addClass("cg-gallery--entering");
        setTimeout(function () {
            $wrapper.removeClass('cg-gallery--entering');
        }, 2000);
    }
}
function evalMobileVersion(wrapper, breakpoint) {
    wrapper.classList.toggle('cg-mosaic-gallery--mobile', window.innerWidth <= breakpoint);
}



},{}],5:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initPhotoswipe = exports.openPhotoswipeGallery = void 0;
function openPhotoswipeGallery(slides, initialSlideIndex, sourceElement, elementFinder, captions) {
    var _a;
    if (captions === void 0) { captions = true; }
    if (!((_a = window['pswpData']) === null || _a === void 0 ? void 0 : _a.openWithData)) {
        throw new Error('PhotoSwipe with Animato extension is not available. Are you sure you included it to this page?');
    }
    var customOptions = {
        showHideOpacity: true,
        captionEl: captions,
    };
    // @ts-ignore
    var photoswipe = window.pswpData.openWithData(slides, initialSlideIndex, sourceElement, elementFinder, customOptions);
    if (!photoswipe || !window['pswpData'].lastDisplayedPhotoswipe) {
        throw new Error('PhotoSwipe\'s Animato extension is probably outdated, you need to update it to work correctly.');
    }
    // Autoplay current video and pause all others
    photoswipe.listen('afterChange', function () {
        var currentIndexHolder = getCurrentSlideHolder(photoswipe);
        var currentVideo = currentIndexHolder === null || currentIndexHolder === void 0 ? void 0 : currentIndexHolder.querySelector('video');
        if (currentVideo) {
            currentVideo.play();
        }
        document.querySelectorAll('.pswp video').forEach(function (v) {
            if (v !== currentVideo) {
                v.pause();
            }
        });
        var currentIframe = currentIndexHolder === null || currentIndexHolder === void 0 ? void 0 : currentIndexHolder.querySelector('iframe');
        if (currentIframe) {
            currentIframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'playVideo' }), 'https://www.youtube.com');
        }
        document.querySelectorAll('.pswp iframe').forEach(function (ifr) {
            if (ifr !== currentIframe) {
                ifr.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'pauseVideo' }), 'https://www.youtube.com');
            }
        });
    });
    photoswipe.listen('initialZoomInEnd', function () {
        var currentIndexHolder = getCurrentSlideHolder(photoswipe);
        var currentVideo = currentIndexHolder === null || currentIndexHolder === void 0 ? void 0 : currentIndexHolder.querySelector('video');
        if (currentVideo) {
            currentVideo.play();
        }
        var currentIframe = currentIndexHolder === null || currentIndexHolder === void 0 ? void 0 : currentIndexHolder.querySelector('iframe');
        if (currentIframe) {
            currentIframe.contentWindow.postMessage(JSON.stringify({ event: 'command', func: 'playVideo' }), 'https://www.youtube.com');
        }
    });
    // Pause all videos on all slides when PSWP closes
    photoswipe.listen('destroy', function () {
        document.querySelectorAll('.pswp video').forEach(function (v) {
            v.pause();
        });
        document.querySelectorAll('.pswp iframe').forEach(function (v) {
            v.remove();
        });
    });
}
exports.openPhotoswipeGallery = openPhotoswipeGallery;
function initPhotoswipe(wrapper, attrName, clickableItemKeys, photoswipeData, captions) {
    if (captions === void 0) { captions = true; }
    wrapper.addEventListener('click', function (event) {
        var closestClickable = event.target.closest('[' + attrName + ']');
        if (closestClickable) {
            var clickableId = closestClickable.getAttribute(attrName);
            if (clickableId) {
                var clickableData = photoswipeData[clickableId];
                if (clickableData) {
                    var foundIndex = clickableItemKeys.indexOf(clickableId);
                    event.stopPropagation();
                    event.preventDefault();
                    openPhotoswipeGallery(clickableItemKeys.map(function (key) { return photoswipeData[key]; }).filter(function (s) { return !!s; }), foundIndex >= 0 ? foundIndex : 0, event.target, function (elementIndex) {
                        var clickableValue = clickableItemKeys[elementIndex];
                        if (clickableValue) {
                            var foundElement = wrapper.querySelector('[' + attrName + '="' + clickableValue + '"]');
                            if (foundElement) {
                                return foundElement;
                            }
                            else {
                                return wrapper;
                            }
                        }
                        return null;
                    }, captions);
                }
            }
        }
    });
}
exports.initPhotoswipe = initPhotoswipe;
function getCurrentSlideHolder(photoswipe) {
    var currentItem = photoswipe.getCurrentIndex();
    var holder = photoswipe.itemHolders.find(function (h) { return h.index === currentItem; });
    if (holder) {
        return holder.el;
    }
    else {
        return undefined;
    }
}



},{}],6:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeRowsGallery = void 0;
function initializeRowsGallery(wrapper, params) {
    var allImagesLoaded = false;
    var fadeInAsap = false;
    var $wrapper = $(wrapper);
    // @ts-ignore
    $wrapper.gallery({
        height: params.height,
        margin: params.margin,
        items: params.items,
        target: params.target,
        afterProcessing: function (images, needsProcessing) {
            if (needsProcessing === undefined) {
                console.warn('You probably use old version of jquery.gallery.js library, which might not be compatible with RowsGallery and may cause entry effect to fail. To prevent this, the entry effect is disabled.');
                needsProcessing = [];
            }
            if (needsProcessing.length === 0) {
                if (fadeInAsap) {
                    runEntryEffect(wrapper);
                }
                else {
                    allImagesLoaded = true;
                }
            }
        },
    });
    if (params.effect) {
        var observer_1 = new IntersectionObserver(function (entries) {
            entries.forEach(function (e) {
                if (e.target === wrapper && e.isIntersecting) {
                    observer_1.disconnect();
                    wrapper.querySelectorAll('[loading="lazy"]').forEach(function (e) { return e.removeAttribute('loading'); });
                    setTimeout(function () {
                        if (allImagesLoaded) {
                            runEntryEffect(wrapper);
                        }
                        else {
                            fadeInAsap = true;
                            setTimeout(function () {
                                // Failsafe run even if not all images are loaded
                                if (wrapper.classList.contains('cg-gallery--before-load')) {
                                    runEntryEffect(wrapper);
                                }
                            }, 2500);
                        }
                    }, 300);
                }
            });
        }, {
            rootMargin: '-50px',
        });
        observer_1.observe(wrapper);
    }
}
exports.initializeRowsGallery = initializeRowsGallery;
function runEntryEffect(wrapper) {
    wrapper.classList.remove('cg-gallery--before-load');
    wrapper.classList.add('cg-gallery--entering');
    setTimeout(function () {
        wrapper.classList.remove('cg-gallery--entering');
    }, 2000);
}



},{}],7:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeSingleGallery = void 0;
function initializeSingleGallery(wrapper, params) {
    if (params.mobileBreakpoint) {
        var resizeWatcher = function () {
            if (window.innerWidth < params.mobileBreakpoint) {
                wrapper.classList.add('cg-single-gallery--mobile');
            }
            else {
                wrapper.classList.remove('cg-single-gallery--mobile');
            }
        };
        window.addEventListener('resize', resizeWatcher);
        resizeWatcher();
    }
    if (params.monitorLabelHeight) {
        var theLabel_1 = wrapper.querySelector('.cg-gallery-label');
        if (theLabel_1) {
            var resizeWatcher = function () {
                wrapper.style.setProperty('--label-h', theLabel_1.clientHeight + 'px');
            };
            window.addEventListener('resize', resizeWatcher);
            resizeWatcher();
        }
    }
}
exports.initializeSingleGallery = initializeSingleGallery;



},{}],8:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeStripGallery = void 0;
var lazy_loading_tools_1 = require("./lazy-loading-tools");
function initializeStripGallery(wrapper, params) {
    if (params === void 0) { params = {}; }
    (0, lazy_loading_tools_1.initializeWhenOnViewport)(params.lazyLoading, function () {
        initNow(wrapper, params);
    }, wrapper);
}
exports.initializeStripGallery = initializeStripGallery;
function initNow(wrapper, params) {
    if (params === void 0) { params = {}; }
    var galWrapper = wrapper.closest('.cg-strip-gallery');
    var par = {
        speed: 200,
        freeMode: true,
        spaceBetween: params.spacing || 5,
        slidesPerView: 'auto',
        grabCursor: true,
    };
    if (params.progressbar) {
        var progressBarWrapper = void 0;
        if (typeof params.progressbar === 'object') {
            progressBarWrapper = params.progressbar;
        }
        else if (typeof params.progressbar === 'string') {
            progressBarWrapper = document.querySelector(params.progressbar);
        }
        else {
            progressBarWrapper = document.createElement('div');
            wrapper.appendChild(progressBarWrapper);
        }
        progressBarWrapper.classList.add('cg-gallery-progressbar');
        par.scrollbar = progressBarWrapper;
        par.scrollbarHide = true;
    }
    if (params.next) {
        var button = void 0;
        if (typeof params.next === 'string') {
            button = document.querySelector(params.next);
        }
        else {
            button = params.next;
        }
        par.nextButton = button;
    }
    if (params.prev) {
        var button = void 0;
        if (typeof params.prev === 'string') {
            button = document.querySelector(params.prev);
        }
        else {
            button = params.prev;
        }
        par.prevButton = button;
    }
    if (params.mobileBreakpoint) {
        var resizeWatcher = function () {
            if (window.innerWidth < params.mobileBreakpoint) {
                galWrapper.classList.add('cg-strip-gallery--mobile');
            }
            else {
                galWrapper.classList.remove('cg-strip-gallery--mobile');
            }
        };
        window.addEventListener('resize', resizeWatcher);
        resizeWatcher();
    }
    var swiper = new Swiper(wrapper, par);
}



},{"./lazy-loading-tools":3}],9:[function(require,module,exports){
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initNow = exports.initializeSwiperGallery = void 0;
var lazy_loading_tools_1 = require("./lazy-loading-tools");
function initializeSwiperGallery(wrapper, params) {
    if (params === void 0) { params = {}; }
    (0, lazy_loading_tools_1.initializeWhenOnViewport)(params.lazyLoading, function () {
        initNow(wrapper, params);
    }, wrapper, false);
}
exports.initializeSwiperGallery = initializeSwiperGallery;
function initNow(wrapper, params) {
    if (params === void 0) { params = {}; }
    if (params.lazyLoading) {
        wrapper.querySelectorAll('[loading="lazy"]').forEach(function (el, index) {
            if (index > 0) {
                el.setAttribute('data-src', el.src);
                el.setAttribute('data-srcset', el.srcset);
                el.classList.add('swiper-lazy');
                el.src = '';
                el.srcset = '';
            }
        });
    }
    var par = {
        speed: 400,
        grabCursor: true,
        lazyLoading: params.lazyLoading,
        lazyLoadingInPrevNext: true,
        lazyLoadingInPrevNextAmount: 2,
        onLazyImageLoad: function (swiper, slide, image) {
            image.removeAttribute('loading');
        }
    };
    if (params.adaptiveHeight) {
        par.autoHeight = true;
    }
    if (params.autoplay) {
        par.autoplay = params.autoplay;
    }
    // if (params.progressbar) {
    // 	let progressBarWrapper: HTMLElement;
    // 	if (typeof params.progressbar === 'object') {
    // 		progressBarWrapper = params.progressbar;
    // 	} else if (typeof params.progressbar === 'string') {
    // 		progressBarWrapper = document.querySelector(params.progressbar)
    // 	} else {
    // 		progressBarWrapper = document.createElement('div');
    // 		wrapper.appendChild(progressBarWrapper);
    // 	}
    // 	progressBarWrapper.classList.add('cg-gallery-progressbar')
    // 	par.scrollbar = progressBarWrapper;
    // 	par.scrollbarHide = true;
    // }
    if (params.next) {
        var button = void 0;
        if (typeof params.next === 'string') {
            button = document.querySelector(params.next);
        }
        else {
            button = params.next;
        }
        par.nextButton = button;
    }
    if (params.prev) {
        var button = void 0;
        if (typeof params.prev === 'string') {
            button = document.querySelector(params.prev);
        }
        else {
            button = params.prev;
        }
        par.prevButton = button;
    }
    if (params.pagination) {
        var pagination = void 0;
        if (typeof params.pagination === 'string') {
            pagination = document.querySelector(params.pagination);
        }
        else {
            pagination = params.pagination;
        }
        par.pagination = pagination;
    }
    if (params.mobileBreakpoint) {
        var mainWrapper_1 = wrapper.closest('.cg-swiper-gallery');
        var breakpointHandler = function () {
            if (window.innerWidth < params.mobileBreakpoint) {
                mainWrapper_1.classList.add('cg-swiper-gallery--mobile');
            }
            else {
                mainWrapper_1.classList.remove('cg-swiper-gallery--mobile');
            }
        };
        window.addEventListener('resize', breakpointHandler);
        breakpointHandler();
    }
    var swiper = new Swiper(wrapper, par);
    if (params.thumbnails) {
        var thumbnailsWrapper_1;
        if (typeof params.thumbnails === 'string') {
            thumbnailsWrapper_1 = document.querySelector(params.thumbnails);
        }
        else {
            thumbnailsWrapper_1 = params.thumbnails;
        }
        if (thumbnailsWrapper_1) {
            var thumbnailsSwiperContainer = thumbnailsWrapper_1.querySelector('.swiper-container');
            var thumbsSwiper_1 = new Swiper(thumbnailsSwiperContainer, {
                slidesPerView: 'auto',
                centeredSlides: true,
            });
            thumbnailsWrapper_1.querySelectorAll('[data-gallery-thumbnail]').forEach(function (thumbnail, indexOfThumbnail) {
                thumbnail.addEventListener('click', function (e) {
                    var code = thumbnail.getAttribute('data-gallery-thumbnail');
                    var mainSlide = wrapper.querySelector('[data-gallery-clickable="' + code + '"]');
                    var index = parseInt(mainSlide.getAttribute('data-number')) - 1;
                    swiper.slideTo(index);
                    thumbsSwiper_1.slideTo(indexOfThumbnail);
                    e.stopPropagation();
                    e.preventDefault();
                });
            });
            thumbnailsWrapper_1.querySelector('[data-gallery-thumbnail]').classList.add('cg-swiper-gallery-thumbnail--active');
            // @ts-ignore
            swiper.on('slideChangeStart', function () {
                thumbsSwiper_1.slideTo(swiper.activeIndex);
                thumbnailsWrapper_1.querySelectorAll('.cg-swiper-gallery-thumbnail--active').forEach(function (a) { return a.classList.remove('cg-swiper-gallery-thumbnail--active'); });
                var active = thumbnailsWrapper_1.querySelector('[data-number="' + (swiper.activeIndex + 1) + '"]');
                if (active) {
                    active.classList.add('cg-swiper-gallery-thumbnail--active');
                }
            });
        }
    }
}
exports.initNow = initNow;



},{"./lazy-loading-tools":3}]},{},[2]);
